const STEAMnav = () => {
  return (

    <div class="row">
      <p>
        <span><b>Flashback:</b></span><span> </span>
        <span><a className="btn btn-info btn-sm" href="/steam2025" role="button">2025</a></span><span> </span>
        <span><a className="btn btn-info btn-sm" href="/steam2024" role="button">2024</a></span><span> </span>
      </p>
      <p >&nbsp;</p>
    </div>


  );
}
export default STEAMnav;
